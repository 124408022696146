<div class="container">
  <div class="grid-container">
    <div class="grid-item">
      <p class="label">Text bubble color</p>
      <color-input [(value)]="toolSettingsModel.chatBubbleColor"></color-input>
    </div>
    <div class="grid-item">
      <p class="label">Text color</p>
      <color-input [(value)]="toolSettingsModel.chatBubbleTextColor"></color-input>
    </div>
  </div>
  <div class="reply-area">
    <input
      type="checkbox"
      [(ngModel)]="toolSettingsModel.showReplyArea"
      id="showReplyCheckbox"
    />
    <label for="showReplyCheckbox" class="reply-label">Show Reply Area</label>
  </div>
  <div *ngIf="toolSettingsModel.showReplyArea" class="reply-suggestion">
    <div *ngIf="toolSettingsModel.showReplyArea" class="reply-suggestion">
      <div class="input-container">
        <label class="label">Reply area suggestion text</label>
        <input
          type="text"
          placeholder="Write a reply ..."
          class="reply-input"
          [(ngModel)]="toolSettingsModel.inputAreaSuggestionText"
        />
      </div>
      <div class="color-container">
        <p class="label">Reply area background color</p>
        <color-input
          [(value)]="toolSettingsModel.textAreaColor"
        ></color-input>
      </div>
    </div>
  </div>
</div>
