import { Component, Injector, Input } from "@angular/core";
import { FileInputComponent } from "../../core/file-input/file-input.component";
import { ColorInputComponent } from "../../core/color-input/color-input.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TextWithTipComponent } from "../../core/text-with-tip/text-with-tip.component";
import { DisplaySettingsModel } from "../../display-tab/display-settings.model";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "bot-icon-tab",
  standalone: true,
  imports: [
    ColorInputComponent,
    FormsModule,
    CommonModule,
    FileInputComponent,
    TextWithTipComponent,
  ],
  templateUrl: "./bot-icon.component.html",
  styleUrls: ["./bot-icon.component.css"],
})
export class BotIconComponent {
  @Input()
  toolSettingsModel!: DisplaySettingsModel;
  @Input()
  toolTips: { fieldHelpKey: string; fieldHelpValue: string }[] = [];
  toolId!: string;

  get autoEnablePopUpDelay() {
    return !!this.toolSettingsModel.autoEnablePopUpDelay
  }

  constructor(private route: ActivatedRoute) {}

  getTooltip(str: string): string | undefined {
    return this.toolTips.find((t) => t.fieldHelpKey === str)?.fieldHelpValue;
  }
  ngOnChanges() {
    // this.toolSettingsModel.launchType = this.toolSettingsModel.calloutMessage
    //   ? "callout"
    //   : "icon";
    this.toolSettingsModel.launchType = "icon";
  }
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.toolId = params["toolId"];
    });
    
  }

  selectedLaunch: string = "icon";

  onChange(event: any) {
    const val = event.target.value;
    if (val === "icon") {
      this.toolSettingsModel.calloutMessage = "";
    }
  }

  onChangeAutoEnable(value: any) {
    console.log(value)
    this.toolSettingsModel.autoEnablePopUpDelay = value ? 10 : 0;
  }
}
