import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable()
export class AppConstants {
  getBaseUrl(): string {
    return environment.serviceUrl;
  }
  
  getBaseChatApiUrl():string {
    return environment.botServiceUrl;
  }

  getLoginUrl(): string {
    return this.getBaseUrl() + "/token/login";
  }

  getSignInUrl(): string {
    return this.getBaseUrl() + "/user/add";
  }

  getFAQUrl(): string {
    return this.getBaseUrl() + "/util/faqListForHomePage";
  }

  getResetPwdUrl(): string {
    return this.getBaseUrl() + "/user/resetPwd";
  }

  getResetPwdCompleteUrl(): string {
    return this.getBaseUrl() + "/user/resetPwdComplete";
  }

  getRefreshTokenUrl(): string {
    return this.getBaseUrl() + "/token/refreshAndGet";
  }

  createKbUrl(): string {
    return this.getBaseUrl() + "/kb/createFromUI";
  }

  createKbRecordUrl(): string {
    return this.getBaseUrl() + "/kb/createKb";
  }

  getToolTips(): string {
    return this.getBaseUrl() + "/util/helpText";
  }

  addSrcToKbUrl(kbId: number): string {
    return this.getBaseUrl() + "/kb/" + `${kbId}` + "/src/add";
  }

  createIndexUrl(kbId: number, srcId: number): string {
    return (
      this.getBaseUrl() + "/kb/createIndex/" + `${kbId}` + "/" + `${srcId}`
    );
  }

  addSrcToIndex(indexId: number): string {
    return this.getBaseUrl() + "/kb/addSrcToIndex/" + `${indexId}`;
  }

  createKbWithExternalSrcUrl(): string {
    return this.getBaseUrl() + "/kb/extsrc/createFromUI";
  }

  getMyKbListUrl(): string {
    return this.getBaseUrl() + "/kb/myKBList";
  }
  getAddIndexToKbUrl(): string {
    //return this.getBaseUrl() + '/kb/'+`${id}`+'/add/noncomposite';
    return this.getBaseUrl() + "/kb/add/noncomposite";
  }

  getAddIndexToKbWithExternalSrcUrl(id: number): string {
    return this.getBaseUrl() + "/kb/extsrc/" + `${id}` + "/add/noncomposite";
  }

  updateIndexUrl(): string {
    return this.getBaseUrl() + "/kb/updateIndex";
  }

  updateIndexWithExternalSrcUrl(id: number): string {
    return this.getBaseUrl() + "/kb/extsrc/updateIndex/" + `${id}`;
  }

  convertToCompositeIndexUrl(id: number): string {
    return this.getBaseUrl() + "/kb/convertToCompositeIndex/" + `${id}`;
  }

  queryUrl(): string {
    return this.getBaseUrl() + "/kb/query";
  }

  promptCreateUrl(): string {
    return this.getBaseUrl() + "/prompt/add";
  }

  getMyPromptListUrl(): string {
    return this.getBaseUrl() + "/prompt/myList";
  }

  getPromptTypeListUrl(): string {
    return this.getBaseUrl() + "/prompt/promptTypeList";
  }

  getPromptTypePromptmapUrl(): string {
    return this.getBaseUrl() + "/prompt/promptTypePromptMap";
  }

  createTool(): string {
    return this.getBaseUrl() + "/tools/createTool";
  }

  getMyToolListUrl(): string {
    return this.getBaseUrl() + "/tools/getMyToolList";
  }

  getKBIndexesList(): string {
    return this.getBaseUrl() + "/kb/myKBIndexList";
  }

  getInitChatAgentUrl(): string {
    return this.getBaseUrl() + "/tools/initAgent";
  }

  getChatServiceUrl(): string {
    return this.getBaseUrl() + "/tools/chat";
  }

  genApiKeyUrl(): string {
    return this.getBaseUrl() + "/user/generateApiKey";
  }

  queryToolCreateUrl(): string {
    return this.getBaseUrl() + "/tools/createQueryTool";
  }

  queryToolQueryUrl(): string {
    return this.getBaseUrl() + "/tools/query";
  }

  createBotAndKbUrl(): string {
    return this.getBaseUrl() + "/tools/createBotAndKb";
  }

  getTool(id: number): string {
    return this.getBaseUrl() + "/tools/get/" + `${id}`;
  }

  getToolDisplaySettings(id: number): string {
    return this.getBaseUrl() + "/tools/" + `${id}` + "/settings";
  }

  addDocToTool(id: number): string {
    return this.getBaseUrl() + "/tools/" + `${id}` + "/addDataFiles";
  }

  conversationHistoryUrl(id: number): string {
    //return this.getBaseUrl() + "/tools/" + `${id}` + "/conversationHistory";
    return this.getBaseChatApiUrl() + "/conversation/history/" + `${id}`;
  }

  addWebPagesToTool(id: number): string {
    return this.getBaseUrl() + "/tools/" + `${id}` + "/addWebPages";
  }

  getDocListForTool(id: number): string {
    return this.getBaseUrl() + "/tools/" + `${id}` + "/docList";
  }

  getUpdateKeywordsUrl(id: number, srcId: number): string {
    return (
      this.getBaseUrl() +
      "/tools/" +
      `${id}` +
      "/" +
      `${srcId}` +
      "/keywords/update"
    );
  }

  updateToolDisplaySettings(id: string): string {
    return this.getBaseUrl() + "/tools/" + `${id}` + "/saveSettings";
  }

  updateTool(): string {
    return this.getBaseUrl() + "/tools/update";
  }

  getGlobalMessageListUrl(): string {
    return this.getBaseUrl() + "/message/global";
  }

  getUserMessageListUrl(): string {
    return this.getBaseUrl() + "/message/user";
  }

  getTokenUsageAllowedUrl(): string {
    return this.getBaseUrl() + "/message/tokenUsageAllowed";
  }

  getAddKeyUrl(): string {
    return this.getBaseUrl() + "/apiKey/add";
  }

  getDeleteKeyUrl(id: number): string {
    return this.getBaseUrl() + "/apiKey/delete/" + `${id}`;
  }

  getListKeysUrl(): string {
    return this.getBaseUrl() + "/apiKey/listKeys";
  }

  // getMyTokenUsageUrl(offset: number, pagesize: number): string {
  //   return (
  //     this.getBaseUrl() +
  //     "/tokenUsage/myUsage/" +
  //     `${offset}` +
  //     "/" +
  //     `${pagesize}`
  //   );
  // }
  //
  // getTokenUsageRowCountUrl() {
  //   return this.getBaseUrl() + "/tokenUsage/myUsage/rowCount";
  // }

  getDownloadIntegrationGuideUrl(id: number): string {
    return this.getBaseUrl() + "/tools/downloadIntegrationGuide/" + `${id}`;
  }

  getSubscriptionListUrl(): string {
    return this.getBaseUrl() + "/subscription/list";
  }

  getTransactionListUrl(): string {
    return this.getBaseUrl() + "/subscription/transactionList";
  }

  getAddSubscriptionUrl(planId: number, withAutorenew: boolean): string {
    return (
      this.getBaseUrl() +
      "/subscription/add/" +
      `${planId}` +
      "/" +
      `${withAutorenew}`
    );
  }

  getCancelSubscriptionUrl(id: number): string {
    return this.getBaseUrl() + "/subscription/cancel/" + `${id}`;
  }

  getEnableAutoRenewSubscriptionUrl(id: number): string {
    return this.getBaseUrl() + "/subscription/autorenew/" + `${id}`;
  }

  getDisableAutoRenewSubscriptionUrl(id: number): string {
    return this.getBaseUrl() + "/subscription/disableAutorenew/" + `${id}`;
  }

  getCreateCheckoutSessionUrl(): string {
    return this.getBaseUrl() + "/payment/createCheckoutSession";
  }

  getCreateSubscriptionCheckoutUrl(id: number): string {
    return (
      this.getBaseUrl() +
      "/payment/createSubscriptionCheckoutSession/" +
      `${id}`
    );
  }

  getCreateBillingPortalForCustomerUrl(): string {
    return this.getBaseUrl() + "/payment/createBillingPortalForCustomer";
  }

  getIsThereAnActiveSubscription(): string {
    return this.getBaseUrl() + "/subscription/isThereAnActiveSubscription";
  }

  getAddSupportMessageUrl(): string {
    return this.getBaseUrl() + "/support/add";
  }

  getDisbledFeatureListUrl(): string {
    return this.getBaseUrl() + "/feature/disabledFeatureList";
  }

  uploadBrandFile(id: string, fieldName: string): string {
    return this.getBaseUrl() + `/tools/${id}/${fieldName}/addBrandFile`;
  }

  getDeploymentCodeSnippet(id:string) :string {
    return this.getBaseUrl() + `/tools/${id}/deplymentCodeSnippet`;
  }

  getAddProspectUrl():string {
    return this.getBaseUrl() + "/support/addProspect";
  }

  getMyInfo():string {
    return this.getBaseUrl() +"/user/myInfo"
  }


  getMyTokenUsageUrl(userId : number,offset: number, pagesize: number): string {
    return (
        this.getBaseChatApiUrl() +
        "/tokenusage/usage/"
        + `${userId}`
        +"/"
        + `${offset}` +
        "/" +
        `${pagesize}`
    );
  }

  getTokenUsageRowCountUrl(userId: number) {
    return this.getBaseChatApiUrl() + "/tokenusage/usage/rowCount/"+`${userId}`;
  }
}
