<div>
  <div class="container mt-3">
    <div class="second">
      <div id="quickCreate">
        <h4 class="mainHeader">Documents</h4>
        <h6>Add new document to {{ toolName }}</h6>

        <form (ngSubmit)="addDocument(f)" #f="ngForm">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label for="docSource">Document Type</mat-label>
                <mat-select
                  id="docSource"
                  [(ngModel)]="srcType"
                  (selectionChange)="onDataSourceOptionChange($event)"
                >
                  <mat-option value="File"
                    >Upload Document (.txt/.pdf/.doc)</mat-option
                  >
                  <mat-option value="WebPages">Web Page</mat-option>
                  <mat-option value="GoogleDocs" disabled
                    >Google Docs (Coming Soon)</mat-option
                  >
                  <mat-option value="S3" disabled>S3 (Coming soon)</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-3" *ngIf="fileUploadDataSourceSelected">
            <div class="col-md-12 mt-3 mb-3">
              <!-- <mat-form-field>
                                <mat-label for="dataSourceDropDown">Upload Docs</mat-label>
                                <ngx-mat-file-input formControlName="localFile" placeholder="Basic Input" #fileInput
                                    (change)="file = fileInput.files[0]"></ngx-mat-file-input>
                            </mat-form-field> -->

              <label for="localFile">Upload Docs</label>:

              <input
                type="file"
                class="form-control"
                id="localFile"
                name="localFile"
                #fileInput
                (change)="file = fileInput.files[0]"
              />
            </div>
          </div>
          <div class="row mt-3" *ngIf="googleDocsDataSourceSelected">
            <div class="col-md-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label for="textfield">List of google docs</mat-label>
                <input
                  matInput
                  type="text"
                  id="textarea"
                  name="srcDataGoogleDocs"
                  ngModel
                  required
                  rows="3"
                  [(ngModel)]="srcDataGoogleDocs"
                  #textarea="ngModel"
                />
                <mat-error *ngIf="!textarea.valid && textarea.touched"
                  >Web pages is required.</mat-error
                >
              </mat-form-field>

              <!-- <label for="textfield">List of google docs</label>
                            <textarea id="textarea" name="srcDataGoogleDocs" class="form-control" rows="3"
                                [(ngModel)]="srcDataGoogleDocs"></textarea> -->
            </div>
          </div>
          <div class="row mt-3" *ngIf="webPageDataSourceSelected">
            <div class="col-md-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label for="textfield">List of web pages</mat-label>
                <input
                  matInput
                  type="text"
                  id="textfield"
                  name="srcDataWebPages"
                  ngModel
                  required
                  rows="3"
                  [(ngModel)]="srcDataWebPages"
                  #textfield="ngModel"
                />
                <mat-error *ngIf="!textfield.valid && textfield.touched"
                  >Web pages is required.</mat-error
                >
              </mat-form-field>

              <!-- <label for="textfield">List of web pages</label>
                            <input type="text" class="form-control" id="textfield" name="srcDataWebPages"
                                placeholder="single webpage ex: https://en.wikipedia.org/wiki/Metabolite etc."
                                [(ngModel)]="srcDataWebPages"> -->
            </div>
          </div>
          <div class="row mt-3" *ngIf="webSiteDataSourceSelected">
            <div class="col-md-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label for="websiteName">Website Name</mat-label>
                <input
                  matInput
                  type="text"
                  id="websiteName"
                  name="srcDataWebsite"
                  ngModel
                  required
                  rows="3"
                  #textfield="ngModel"
                />
              </mat-form-field>

              <!-- <label for="websiteName">Website Name</label>
                            <input type="text" class="form-control" id="websiteName"
                                placeholder="example : www.anywebsi .com" formControlName="srcDataWebsite"> -->

              <div style="font-size: x-small">
                We will crawl over the entire website and try to add webpages
                into KB. Currently only 20 pages are supported
              </div>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="submit" class="btn upd" [disabled]="!(srcDataWebPages || file)">
              Add Document
              <span
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button type="button" class="btn can" style="margin-left: 1em">
              Cancel
            </button>
          </div>

          <div style="margin-top: 2em">
            <div>
              <h6>List of documents for {{ toolName }}</h6>
              <table width="100%">
                <thead>
                  <th>File</th>
                  <th>Keywords</th>
                  <th></th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let toolDocModel of toolDocModelList; let i = index"
                    style="margin-bottom: 10px"
                  >
                    <td>{{ toolDocModel.name }}</td>
                    <td>
                      <input
                        type="text"
                        id="keywords"
                        name="keywords_{{ i }}"
                        [(ngModel)]="toolDocModel.keywords"
                        size="30"
                      />
                    </td>
                    <td style="text-align: right">
                      <button
                        type="button"
                        (click)="updateKeywords(i)"
                        name="updateKeyWords"
                        id="updateKeywords"
                        class="btn upd-small"
                      >
                        Update
                        <span
                          *ngIf="isLoadingUpdateKeywords === i"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- <div class="row" style="margin-top:0.5em">
                        <div *ngIf="isError" class="alert alert-danger">{{ addDocumentMessage }}</div>
                        <div *ngIf="isSuccess" class="alert-success">{{ addDocumentMessage }}</div>
                    </div> -->
        </form>
      </div>
    </div>
  </div>
</div>
