<div class="container">
  <select [(ngModel)]="toolSettingsModel.headerType" class="select-box">
    <option class="option" value="icon">Header icon with text</option>
    <option class="option" value="image">Header image</option>
  </select>
  <div *ngIf="toolSettingsModel.headerType === 'icon'" class="option-content">
    <div class="input-box">
      <div class="bold-text">
        <pi
          [text]="'Header icon'"
        ></pi>
      </div>
      <div>
        <customFileInput
          [fieldName]="'chatHeaderIconUrl'"
          [(value)]="toolSettingsModel.chatHeaderIconUrl"
          [tool]="toolId"
        ></customFileInput>
      </div>
    </div>
    <div class="input-box">
      <label class="bold-text">Header text</label>
      <input
        type="text"
        placeholder="Write a reply ..."
        style="
          width: 100%;
          margin-top: 0.5rem;
          padding: 0.625rem 0.875rem;
          border-radius: 0.375rem;
          outline: none;
        "
        [(ngModel)]="toolSettingsModel.headerDescription"
      />
    </div>
  </div>
  <div *ngIf="toolSettingsModel.headerType === 'image'" class="option-content">
    <div class="input-box">
      <label class="bold-text">Header image</label>
      <customFileInput
        [fieldName]="'chatHeaderIconUrl'"
        [(value)]="toolSettingsModel.chatHeaderIconUrl"
        [tool]="toolId"
      ></customFileInput>
    </div>
  </div>
  <div *ngIf="toolSettingsModel.headerType === 'icon'" class="option-content">
    <div class="grid-container">
      <div>
        <p class="bold-text">Alignment</p>
        <select
          class="select-box"
          [(ngModel)]="toolSettingsModel.headerAlignment"
        >
          <option class="option" value="center">Center</option>
          <option class="option" value="left">Left</option>
        </select>
      </div>
      <div class="color-input-container">
        <p class="bold-text">Background Color</p>
        <color-input
          [(value)]="toolSettingsModel.headerBackground"
        ></color-input>
      </div>
      <div>
        <p class="bold-text">Font</p>
        <select class="select-box" [(ngModel)]="toolSettingsModel.headerFont">
<!--          <option class="option" value="Global">Global</option>-->
          <option class="option" value="Arial, sans-serif">Arial</option>
          <option class="option" value="Helvetica, sans-serif">
            Helvetica
          </option>
          <option class="option" value="Times New Roman, serif">
            Times New Roman
          </option>
          <option class="option" value="Courier New, monospace">
            Courier New
          </option>
          <option class="option" value="Verdana, sans-serif">Verdana</option>
          <option class="option" value="Georgia, serif">Georgia</option>
          <option class="option" value="Tahoma, sans-serif">Tahoma</option>
        </select>
      </div>
      <div class="color-input-container">
        <p class="bold-text">Font color</p>
        <color-input
          [(value)]="toolSettingsModel.headerTextColor"
        ></color-input>
      </div>
    </div>
  </div>
  <div *ngIf="toolSettingsModel.headerType === 'image'" class="option-content">
    <div class="grid-container">
      <div>
        <p class="bold-text">Alignment</p>
        <select class="select-box" [(ngModel)]="toolSettingsModel.headerAlignment">
          <option class="option" value="center">Center</option>
          <option class="option" value="left">Left</option>
        </select>
      </div>
      <div class="color-input-container">
        <p class="bold-text">Background Color</p>
        <color-input
          [(value)]="toolSettingsModel.headerBackground"
        ></color-input>
      </div>
      <div class="color-input-container">
        <p class="bold-text">Header icons color</p>
        <color-input
          [(value)]="toolSettingsModel.headerTextColor"
        ></color-input>
      </div>
    </div>
  </div>
</div>
