import { Injectable } from "@angular/core";
import { AppConstants } from "../app-constants";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Subject, Observable, map } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { ToolModel } from "./tool.model";
import { ToolDocModel } from "./tool-doc.model";
import { take, timeout } from "rxjs/operators";
import { DisplaySettingsModel } from "./configure/components/display-tab/display-settings.model";

@Injectable()
export class ToolService {
  toolCreatedSubject = new Subject<any>();

  toolListSubject = new Subject<any>();
  initChatAgentSubject = new Subject<any>();
  chatSubject = new Subject<any>();
  querySubject = new Subject<any>();
  getToolSubject = new Subject<ToolModel>();
  getToolDisplaySettingsSubject = new Subject<DisplaySettingsModel>();
  updateToolSubject = new Subject<string>();
  updateToolDisplaySettingsSubject = new Subject<string>();
  addDocSubject = new Subject<string>();
  getToolDocListSubject = new Subject<ToolDocModel[]>();
  updateKeywordsSubject = new Subject<any>();
  getDeplymentCodeSnippetSubject = new Subject<any>();

  constructor(
    private appConstants: AppConstants,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {
    console.log("----------inside Tool Service -------");
  }

  // addTool(toolModel: ToolModel) {
  //
  //     console.log('inside addChatAgent');
  //     var headers = new HttpHeaders();
  //
  //     headers = headers.append('Authorization', this.authService.getAuthToken());
  //
  //     const url = this.appConstants.createTool();
  //
  //     //console.log('url -->' + url);
  //     this.http.post(url, toolModel, {headers: headers}).subscribe(
  //         (response )=>{
  //
  //             this.toolCreatedSubject.next(response);
  //
  //         },
  //         (error) => {
  //             this.toolCreatedSubject.next(error);
  //         }
  //     )
  //
  //     //return this.http.post(url, toolModel, {headers: headers});
  //
  // }
  // addTool(toolModel: ToolModel) {
  //
  //     console.log('inside addChatAgent');
  //     var headers = new HttpHeaders();
  //
  //     headers = headers.append('Authorization', this.authService.getAuthToken());
  //
  //     const url = this.appConstants.createTool();
  //
  //     //console.log('url -->' + url);
  //     this.http.post(url, toolModel, {headers: headers}).subscribe(
  //         (response )=>{
  //
  //             this.toolCreatedSubject.next(response);
  //
  //         },
  //         (error) => {
  //             this.toolCreatedSubject.next(error);
  //         }
  //     )
  //
  //     //return this.http.post(url, toolModel, {headers: headers});
  //
  // }

  initializeSubjects() {
    this.toolCreatedSubject = new Subject<any>();
  }

  createBotAndKb(name: string, toolType: string) {
    console.log("--- inside service createToolAndKb ---");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.createBotAndKbUrl();

    //5 minutes timeout
    var timeout_duration = 300000;

    this.http
      .post(url, { name: name, toolType: toolType }, { headers: headers })
      .pipe(timeout(timeout_duration))
      .subscribe(
        (response) => {
          console.log("createToolAndKb:success : " + JSON.stringify(response));
          this.toolCreatedSubject.next(response);
        },
        (error) => {
          console.log("createToolAndKb:error : " + JSON.stringify(error));
          this.toolCreatedSubject.error(error);
        }
      );
  }

  addDocToTheTool(formData: FormData, toolId: number) {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.addDocToTool(toolId);
    console.log("url -->" + url);
    this.http.post<string>(url, formData, { headers: headers }).subscribe(
      (response: string) => {
        console.log(response);
        this.addDocSubject.next(response);
        this.getDocListForTool(toolId);
      },
      (error: any) => {
        console.log(error);
        this.addDocSubject.next(error);
      }
    );
  }

  conversationHistory(toolId: number): any {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", this.authService.getAuthToken());
    const url = this.appConstants.conversationHistoryUrl(toolId);
    return this.http.get<string>(url, { headers: headers });
  }

  addWebPagesToTheTool(formData: FormData, toolId: number) {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.addWebPagesToTool(toolId);

    this.http.post<string>(url, formData, { headers: headers }).subscribe(
      (response: string) => {
        this.addDocSubject.next(response);
      },
      (error: any) => {
        this.addDocSubject.next(error);
      }
    );
  }

  updateTool(toolModel: ToolModel) {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.updateTool();
    //console.log('url -->' + url);
    this.http.post<string>(url, toolModel, { headers: headers }).subscribe(
      (response: string) => {
        this.updateToolSubject.next(response);
      },
      (error) => {
        this.updateToolSubject.next(error);
      }
    );
  }

  updateToolDisplaySettings(toolModel: DisplaySettingsModel, toolId: string) {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", this.authService.getAuthToken());
    const url = this.appConstants.updateToolDisplaySettings(toolId);
    //console.log('url -->' + url);
    this.http.post<string>(url, toolModel, { headers: headers }).pipe(take(1)).subscribe(
      (response: string) => {
        this.updateToolDisplaySettingsSubject.next(response);
      },
      (error) => {
        this.updateToolDisplaySettingsSubject.next(error);
      }
    );
  }
  getToolDisplaySettings(id: number) {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", this.authService.getAuthToken());
    const url = this.appConstants.getToolDisplaySettings(id);
    this.http
        .get(url,  { headers: headers })
      .pipe(
        map((response) => {
          let responseObj = JSON.parse(JSON.stringify(response));
          let toolModel = new DisplaySettingsModel();
          console.log(toolModel);
          toolModel = responseObj;
          console.log(toolModel);
          return toolModel;
        })
      )
      .subscribe(
        (responseObj) => {
          this.getToolDisplaySettingsSubject.next(responseObj);
        },
        (error) => {
          console.log(error);
          this.getToolDisplaySettingsSubject.error(
            "Error occured while getting tool "
          );
        }
      );
  }

  getTool(id: number) {
    var headers = new HttpHeaders();
    headers = headers.append("Authorization", this.authService.getAuthToken());
    const url = this.appConstants.getTool(id);
    this.http
      .get(url, { headers: headers })
      .pipe(
        map((response) => {
          var responseObj = JSON.parse(JSON.stringify(response));
          var toolModel = new ToolModel();
          toolModel = responseObj;
          console.log(toolModel);
          return toolModel;
        })
      )
      .subscribe(
        (responseObj) => {
          this.getToolSubject.next(responseObj);
        },
        (error) => {
          this.getToolSubject.error("Error occured while getting tool ");
        }
      );
  }

  getTooltip(): Observable<any> {
    const url = this.appConstants.getToolTips();
    const headers = new HttpHeaders({
      Authorization: this.authService.getAuthToken(),
    });
    return this.http.get<any[]>(`${url}`, {
      headers: headers,
    });
  }

  getDocListForTool(id: number) {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getDocListForTool(id);

    //console.log('getTool url --->' + url);
    //console.log('getTool url --->' + url);

    this.http
      .get(url, { headers: headers })
      .pipe(
        map((response) => {
          var responseObj = JSON.parse(JSON.stringify(response));
          var toolSrFileModelList = [];
          toolSrFileModelList = responseObj;
          return toolSrFileModelList;
        })
      )
      .subscribe(
        (responseObj) => {
          this.getToolDocListSubject.next(responseObj);
        },
        (error) => {
          this.getToolDocListSubject.error(
            "Error occured while list of src Files for tool "
          );
        }
      );
  }

  updateKeyWords(id: any, srcId: number, keywords: string) {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getUpdateKeywordsUrl(id, srcId);

    //console.log('updateKeyWords url --->' + url);
    //console.log('updateKeyWords url --->' + url);

    var formData: any = new FormData();
    formData.append("keywords", keywords);

    this.http.post(url, formData, { headers: headers }).subscribe(
      (data) => {
        this.updateKeywordsSubject.next("keyswords updated successfully");
      },
      (error) => {
        this.updateKeywordsSubject.error(
          "Error occured while updating keywords"
        );
      }
    );
  }

  getMyToolslist() {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getMyToolListUrl();

    this.http.get(url, { headers: headers }).subscribe(
      (response) => {
        console.log("getMyToolslist: inside success");
        this.toolListSubject.next(response);
      },
      (error) => {
        console.log("getMyToolslist: inside error");
        this.toolListSubject.error("Error occured while getting list of tools");
      }
    );
  }

  initChatAgent(formData: FormData) {
    console.log("inside initChatAgent " + formData);
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getInitChatAgentUrl();

    //const reqObj ={agentId:id}
    //const reqObj ={agentId:id}

    //const httpParam = new HttpParams().set('agentId',id);
    //const httpParam = new HttpParams().set('agentId',id);

    this.http
      .post(url, formData, { headers: headers })
      .pipe(
        map((response) => {
          var responseObj = JSON.parse(JSON.stringify(response));
          console.log("responseObj---->" + JSON.stringify(responseObj));
          return responseObj;
        })
      )
      .subscribe(
        (responseObj) => {
          console.log("responseObj 1 ---->" + JSON.stringify(responseObj));
          this.initChatAgentSubject.next(responseObj);
        },
        (error) => {
          this.initChatAgentSubject.error(
            "Error occured while initializing chat agent"
          );
        }
      );
  }

  chat(
    agentId: number,
    message: string,
    conversationSessionId: string,
    toolWebKey: string
  ) {
    console.log("inside chat()");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.getChatServiceUrl();

    const data = {
      agentId: agentId,
      message: message,
      conversationSessionId: conversationSessionId,
      toolWebKey: toolWebKey,
    };

    this.http
      .post(url, data, { headers: headers })
      .pipe(
        map((response) => {
          var responseObj = JSON.parse(JSON.stringify(response));
          return responseObj;
        })
      )
      .subscribe(
        (responseObj) => {
          this.chatSubject.next(responseObj);
        },
        (error) => {
          this.chatSubject.error("Error occured while getting chat reply");
        }
      );
  }

  query(
    toolId: number,
    query: string,
    conversationSessionId: string,
    toolWebKey: string
  ) {
    console.log("inside query()");
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    const url = this.appConstants.queryToolQueryUrl();

    const data = {
      toolId: toolId,
      queryStr: query,
      conversationSessionId: conversationSessionId,
      toolWebKey: toolWebKey,
    };

    this.http
      .post(url, data, { headers: headers })
      .pipe(
        map((response) => {
          var responseObj = JSON.parse(JSON.stringify(response));
          console.log("responseObj -->" + responseObj);
          return responseObj;
        })
      )
      .subscribe(
        (responseObj) => {
          this.querySubject.next(responseObj);
        },
        (error) => {
          this.querySubject.error("Error occured while getting query reply");
        }
      );
  }

  downloadIntegrationGuide(toolId: number): Observable<HttpResponse<Blob>> {
    console.log("Downloading integration guide for toolId " + toolId);

    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());

    headers = headers.append("Content-Type", "application/octet-stream");

    const url = this.appConstants.getDownloadIntegrationGuideUrl(toolId);

    return this.http.get(url, {
      headers: headers,
      observe: "response",
      responseType: "blob",
    });
  }

  uploadFile(formData: FormData, toolId: string, fieldName: string): Observable<any> {
    var headers = new HttpHeaders();

    headers = headers.append("Authorization", this.authService.getAuthToken());
    const url = this.appConstants.uploadBrandFile(toolId, fieldName);
    return this.http.post<any>(url, formData, { headers: headers });
  }

  getDeploymentCodeSnippets(toolId= '')  {

    const url = this.appConstants.getDeploymentCodeSnippet(toolId);
    var headers = new HttpHeaders();
    headers = headers.append("Authorization", this.authService.getAuthToken());
    this.http.get(url,{headers:headers}).subscribe(
        (data) =>{
          this.getDeplymentCodeSnippetSubject.next(data);
        },
        (error) => {
          this.getDeplymentCodeSnippetSubject.error(error);
        }
    )
    
  }

}
