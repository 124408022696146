export class DisplaySettingsModel {
  toolId: number = 0;
  botResponseBubbleColor: string = "";
  botResponseBubbleTextColor: string = "";
  showAgentResponsiveness: "enabled" | "disabled" = "enabled";
  bottomStopColor: string = "";
  launchCards: "enabled" | "disabled" = "enabled";
  botAvatarImageUrl: string = "";
  chatHeaderIconUrl: string = "";
  chatWidgetPosition:
    | "bottom-left"
    | "bottom-right"
    | "top-left"
    | "top-right" = "bottom-right";
  headerAlignment: "left" | "center" | "right" = "center";
  headerType: "icon" | "image" = "icon";
  headerFont: string = "";
  globalFontType: string = "";
  globalFontColor: string = "#000000";
  headerBackground: string = "#000000";
  headerIconsColor: string = "#000000";
  backgroundOption: "solid" | "image" | "color" | "gradient" | "video" =
    "solid";
  chatCloseButtonPosition:
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right" = "top-right";
  webMenu: "enabled" | "disabled" = "enabled";
  popUpMessage: string = "";
  autoPopUpWebChatType: "immediate" | "delayed" = "delayed";
  refreshChat: boolean = false;
  backgroundTransparency: number = 1;
  autoEnablePopUpDelay: number = 0;
  wallpaperImageUrl: string = "";
  wallpaperVideoUrl: string = "";
  defaultEditResponse: string = "";
  disableSmoOptions: boolean = false;
  editUserInput: boolean = false;
  voiceContinuousConvEnabled: boolean = false;
  voiceConversationEntitlement: boolean = false;
  voiceConversationEnabled: boolean = false;
  voiceInputEnabled: boolean = false;
  themeColor: string = "";
  headerDescription: string = "";
  headerTextColor: string = "";
  hostname: string = "";
  iconUrl: string = "";
  launchScreenTitle: string = "";
  launchScreenHeader: string = "";
  midStopColor: string = "";
  queryTitle: string = "";
  launchCardsType: "carousel" | "grid" = "carousel";
  queryPlaceholder: string = "";
  textAreaColor: string = "#000000";
  inputAreaSuggestionText: string = "";
  showAgentProfiles: boolean = false;
  showReplyArea: boolean = false;
  isLaunchScreenEnabled: boolean = false;
  buttonSelectorColor: string = "";
  topStopColor: string = "";
  themeFont: string = "";
  chatBubbleColor: string = "#000000";
  chatBubbleTextColor: string = "#000000";
  phraseHints: "enabled" | "disabled" = "enabled";
  voiceResponseGender: "male" | "female" = "female";
  isAgentNotificationsEnabled: boolean = false;
  isFadingMessagesEnabled: boolean = false;
  isCtaResponseHidden: boolean = false;

  customCssLink: string = "";
  customJsLink: string = "";
  calloutMessage: string = "";
  buttonsColor: string = "#000000";
  clearConversationHistoryMenuEnabled: boolean = true;
  chatMenuIconsShown: boolean = true;
  replyAreaBackground: string = "#000000";
  userBubbleColor: string = "#000000";
  userTextColor: string = "#000000";
  backgroundColor: string = "#000000";
  launchType: "icon" | "callout" = "icon";
  fileUploadEnabled:boolean = false
  askCustomerInfoEnabled :boolean = false;
}
